<template>
	<div class="modal" @keydown.esc="closeModal(0)"  @keydown.enter="closeModal(1)">
		<div class="modalCont">

			<div class="modalHead">Neue Pflanze anlegen</div>
				<div ref="msgValidate" id="msgValidate"></div>

			<div class="modalMain">


				<div>
					<div>Name Latein</div>
					<div><input type="text" ref="name_lat" v-model="neuePflanzeDat.data.name_lat"></div>
				</div>

				<div>
					<div>Name Deutsch</div>
					<div><input type="text" v-model="neuePflanzeDat.data.name_de"></div>
				</div>

				<div>
					<div>Beschreibung</div>
					<div><input type="text" v-model="neuePflanzeDat.data.description"></div>
				</div>

				<div>
					<div>Preis</div>
					<!-- <div><input type="text" v-model="tl.decimalToGerman(neuePflanzeDat.data.preis)"></div> -->
					<div><input type="text" v-model="neuePflanzeDat.data.preis"></div>
				</div>

				<div style="position: relative;">
					<div>Sichtbar</div>
					<div class="sichtbar">
						<div title="sichtbar" v-if="neuePflanzeDat.data.visible == 1"><svg class="iVis"  @click="toggleItemVis"><use xlink:href="/sys_img/icons.svg#visible"/></svg></div>
						<div title="nicht sichtbar" v-else ><svg class="iUvis" @click="toggleItemVis"><use xlink:href="/sys_img/icons.svg#unvisible"/></svg></div>
					</div>
				</div>

				<div>
					<div>Kategorie</div>
					<div>
						<select v-model="neuePflanzeDat.data.kategorie">
							<option v-for="(k) in kats" :value="k.id">{{k.text}}</option>

						</select>
					</div>
				</div>

				<div>
					<div>Blütezeit</div>
					<div><input type="text" v-model="neuePflanzeDat.data.bluetezeit"></div>
				</div>

				<div>
					<div>Blütenfarbe</div>
					<div><input type="text" v-model="neuePflanzeDat.data.bluetenfarbe"></div>
				</div>

				<div>
					<div>Standort</div>
					<div><input type="text" v-model="neuePflanzeDat.data.standort"></div>
				</div>

				<div>
					<div>Verwendung</div>
					<div><input type="text" v-model="neuePflanzeDat.data.verwendung"></div>
				</div>

				<div>
					<div>Größe</div>
					<div><input type="text" v-model="neuePflanzeDat.data.groesse"></div>
				</div>
				<div>
					<div>Liefergröße/ Stückzahl</div>
					<div><input type="text" v-model="neuePflanzeDat.data.liefergroesse"></div>
				</div>
				<div>
					<div>Sonstiges</div>
					<div><input type="text" v-model="neuePflanzeDat.data.sonstig"></div>
				</div>

			</div>


			<div class="modalFooter">
				<div><button  @click="closeModal(0)">Abbrechen</button></div>
				<div><button  @click="closeModal(1)">Speichern</button></div>

			</div>
		</div>
	</div>
</template>

<script>
import * as tools from '@/tools.js'; 


	export default {
		name: 'neuePflanzeDat',
		components: {
			// tools,

		},
		props:[
		'par',
		'kats',

		],
		mounted(){
			this.$refs.name_lat.focus();



		}, // end mounted 
		data: 
		function(){
			return {
				neuePflanzeDat : this.par,
				tl: tools,

			}
		}, // end data
		methods: {

			changeDec(p){
				return p;
			},
					toggleItemVis(){


							// alert("");
				if (this.neuePflanzeDat.data.visible) {
					this.neuePflanzeDat.data.visible = 0;
				} else {
					this.neuePflanzeDat.data.visible = 1;

				}
				this.$forceUpdate();
			},
			closeModal(p){
				if (p === 1) {
				// doValidate


				let valArr = [
				['Name Latein', this.neuePflanzeDat.data.name_lat,'notNull'], 
				['Name Deutsch', this.neuePflanzeDat.data.name_de,'notNull'], 
				['Preis', this.neuePflanzeDat.data.preis,'notNull'], 
				['Preis', this.neuePflanzeDat.data.preis,'dezimal'], 

				];

				let valMsg = tools.doValidate(valArr);




				this.$refs.msgValidate.innerHTML = '';
				this.$refs.msgValidate.style.display = 'none';
				if(valMsg)
				{
					this.$refs.msgValidate.innerHTML = valMsg;
					this.$refs.msgValidate.style.display = 'block';
					return false;
				}
			}

			this.neuePflanzeDat.callback(p);
		},



		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.container {
	// background-color: #EEE472;
	
}

.modal {
	position: absolute;
	top: 0;
	left: 0rem;
	background-color: rgb(0,0,0, .5);
	width: 100vw;
	height: 100vh;
	// z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;

		& > .modalCont {
			background-color: white;

			padding: 2rem;

			border-radius: 10px;
			box-shadow: 2px 2px 2px #555;

		}

		& .modalMain {
			// min-height: 7rem;
			width: 60rem;
			display: flex;
			flex-wrap: wrap;
			& div {
				box-sizing: border-box;
				flex: 0 0 50%;
				// margin: .4rem 0;

				& > div:first-child  {
				color: gray;
				margin: 0.2rem 0.1rem 0;
				}



				& div input {
					width: 94%;
				// line-height: 12px !important;
				border: 1px solid #ddd;
				border-radius: 4px;
				padding: .2rem .6rem;
				}


			}

			& select {
				width: 100%;
			}
		}

	& .modalHead {
background-color: #ddd;
padding: 0.4rem 0.8rem;
font-size: 1.1rem;
font-weight: bold;
text-align: center;

	}
	& .modalFooter {
		display: flex;
		justify-content: center;
		align-items: baseline;
		
		& div {
			margin: 1rem;
			min-width: 12rem;

			& button {
				width: 100%;
			}
		}
	}
}

#msgValidate  {

	// background-color: #F7F3CD;
	text-align: left;
	padding: 3px 11px;
	margin: .5rem;
	border: 2px solid #db5050;
	border-radius: 4px;
	box-shadow: 2px 2px 4px #8a8383;
	display: none;
}

.sichtbar {
	position: absolute;
	stroke: black;
	height: 3rem;
	top: 0;
	left: 6rem;

	& svg {
		width:  2rem;
		height: 2rem;
		cursor: pointer;
	}
	
}
.iVis {
	stroke: #72b579;
}

</style>
