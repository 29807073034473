<template>
	<div class="container pfk">

<div style="margin: 1rem"><h2>Pflanzen Katalog Editor</h2></div>

		<vir-dialog v-if="dialog.visible" :par="dialog"></vir-dialog>


		<imgImport  v-if="impImg.visible" :par="impImg"></imgImport>


		<div class="header header1">
			<div class="newItem"><button @click="getNeuePflanze()"> neuer Eintrag </button></div>
			<div class="newKat"><button @click="newPfKat"> neue Kategorie </button></div>
			<div class="delKat"><button @click="delKat"> Kategorie löschen </button></div>
		</div>

		<div class="header header2">			
			<div class="kat">
				<div>Kategorie</div>
				<div>
					<select @change="changeSelKat"  v-model="selKat">
						<option v-for="(k) in pkKats" :value="k.id" :key="k.id">{{ k.text }}</option>
					</select>
				</div>
			</div>
			<div class="filter">
				<div></div>
				<div class="icon exit"><input type="text" placeholder="Filter" @keyup="filterPkList" v-model="pkFilterInput">
					<div @click="resetPkFilter"><svg><use xlink:href="/sys_img/icons.svg#exit"/></svg></div>
					</div>
				</div>
			</div>

		<neuePflanze v-if="neuePflanzeDat.visible" :par="neuePflanzeDat" :kats="pkKats"></neuePflanze>
		<pfKatEditor v-if="newPfKatDat.visible" :par="newPfKatDat" ></pfKatEditor>


		<div v-if="pkFilter.length > 0" class="pkList">
			
			<div v-for="(v,i) in pkFilter" class="items">
				<div class="item img" @click="importImg(i)"><img :src="$store.state.server+'/img/pk/'+v.img_thumbnail" width="70" alt="Viridarium Pflanzenkatalog Thumbnail"></div>
				<div class="item" :title="getPfTitle(v)">
					<div class="name_lat"> {{ v.name_lat }}</div>
					<div class="name_de">{{ v.name_de }}</div>
				</div>
				<div class="item preis">{{ v.preis }}</div>
				<div class="item icons">
					<div title="sichtbar" v-if="v.visible == 1"><svg class="iVis"  @click="toggleItemVis(i)"><use xlink:href="/sys_img/icons.svg#visible"/></svg></div>
					<div title="nicht sichtbar" v-else ><svg class="iUvis" @click="toggleItemVis(i)"><use xlink:href="/sys_img/icons.svg#unvisible"/></svg></div>
					<div title="bearbeiten"><svg class="iEdit" @click="editPflanze(i)" ><use xlink:href="/sys_img/icons.svg#edit"/></svg></div>
					<div title="löschen"><svg class="iDel" @click="delPfItem(i)"><use xlink:href="/sys_img/icons.svg#delete"/></svg>
					</div>
				</div>
			</div>			


		</div>





	</div>
</template>

<script>
	import  virDialog from "@/components/Dialog.vue";
	import  neuePflanze from "@/components/NeuePflanze.vue";
	import  imgImport from "@/components/PkImgImport.vue";
	import  pfKatEditor from "@/components/PfKatEditor.vue";
	import axios from 'axios'
	import * as tools from '@/tools.js'; 

	export default {
		name: 'PflanzenkatalogList',
		components: {
		neuePflanze,
		imgImport,
		virDialog,
		pfKatEditor,
		
		},
		mounted(){
			// console.log(tools.inputToFloat("12,2"));

			this.getIndex();

		}, // end mounted 
		data: 
		function(){
			return {
				pkKats: [],
				selKat: 4,
				pk: [],
				pkFilter: [],
				pkFilterInput: '',
				neuePflanzeDat: {visible: false, data: null, callback: null},
				impImg: {visible: false, data: {tn: null, full: null}, callback: null},
				dialog: {visible:false, data: {}, callback: null},
				newPfKatDat: {visible: false, data: null, callback: null},


				tl: tools,
			}
		}, // end data
		methods: {

			newPfKat(){

				let self = this;
				this.newPfKatDat.callback = function(p, val){

					if (p === 1) {


						self.saveNewPfKat(val);

					}
					self.newPfKatDat.visible = false;
				};

				this.newPfKatDat.visible = true;


			},

			saveNewPfKat(val){

				let self = this;
				let url = this.$store.state.server + '/be/saveNewPfKat.php';


				let id = this.selKat;

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { val:val },
				})
				.then(function (resp) {

					if(resp.data.data[0] === false){
						alert(resp.data.data[1]);
						return;
					} else {


						self.pkKats = resp.data.data[2];
						self.selKat = resp.data.data[3];
						self.pk = self.pkFilter = [];
					}





				});
			},

			delKat(){

				if (this.pk.length > 0) {
					alert("Die Kategorie muss leer sein um gelöscht zu werden!");
					return;
				}


				let r = confirm("Sie die Kategorie gelöscht zu werden?");
				if (r != true) {
					return;
				}


				let self = this;
				let url = this.$store.state.server + '/be/delPfKat.php';


				let id = this.selKat;

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { id:id },
				})
				.then(function (resp) {

					if (resp.data.data[0]) {
						self.selKat = null;
						self.pkKats =  resp.data.data[1];
						self.pk = self.pkFilter = [];
						
					}



					// self.$router.go();
					// windows.history.go();
					// self.$forceUpdate();




				});
			},

			getPfTitle(o){

				let op = '';
				for (const i in o) {

					if(! o[i]){
		// continue;
	}

	let key = i;
	let val = o[i] ? o[i] : ' ------------------------ ';
	op += key + ': ' + val + '' + '\n';
}

return op;
},

			importImg(key){
				

				let self = this;
				this.impImg.callback = function(p){

					if (p === 1) {


						self.changeImg(key);


					}

					self.impImg.visible = false;
				};


				this.impImg.visible = true;




			},

			changeImg(key){

				let self = this;
				let url = this.$store.state.server + '/be/changePkImg.php';


				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { item:this.pkFilter[key], thumbNail: this.impImg.data.tn, fullImg: this.impImg.data.full },
				})
				.then(function (resp) {




					self.pk = self.pkFilter = resp.data.data;

					self.$router.go();
					// windows.history.go();
					// self.$forceUpdate();
	



				});


			},
			resetPkFilter(){
				this.pkFilterInput = '';
				this.filterPkList(); 
			},
			filterPkList(){
				let st = this.pkFilterInput;
						this.pkFilter = [];

				for (const i in this.pk) {

					var re = new RegExp(st, 'i');


					let tagD = this.pk[i].name_de;
					let tagL = this.pk[i].name_lat;
					if ((tagD !== null && tagD.match(re))  || (tagL !== null && tagL.match(re))) {


						this.pkFilter.push(this.pk[i]);
					}
				}

			},

			editPflanze(key){


				if (this.selKat == 1) {
					alert("Bitte zuerst eine Kategorie wählen");
					return false;
				}

					this.neuePflanzeDat.data = this.pkFilter[key];


				let self = this;
				this.neuePflanzeDat.callback = function(p){



					if (p === 1) {


						self.savePflanze(self.neuePflanzeDat.data);

					}
					self.neuePflanzeDat.visible = false;
				};

				this.neuePflanzeDat.visible = true;
			},
			getNeuePflanze(){

				if (this.selKat == 1) {
					alert("Bitte zuerst eine Kategorie wählen");
					return;
				}

				this.resetPkFilter();

					this.neuePflanzeDat.data = {};

					for (let k in this.pk[0]) {
						let v = this.pk[0][k];
						this.neuePflanzeDat.data[k] = null;
					}
				// } // end if
				this.neuePflanzeDat.data['visible'] = 1;
				this.neuePflanzeDat.data['name_lat'] = "";
				this.neuePflanzeDat.data['name_de'] = "";
				this.neuePflanzeDat.data['kategorie'] = this.selKat;
				this.neuePflanzeDat.data['preis'] = "";
				this.neuePflanzeDat.data['img_thumbnail'] = '0-pk-tn.jpg';
				this.neuePflanzeDat.data['img_full'] = '0-pk-fl.jpg';




				let self = this;
				this.neuePflanzeDat.callback = function(p){

					if (p === 1) {
						self.savePflanze(self.neuePflanzeDat.data);

					}
					self.neuePflanzeDat.visible = false;
				};

				this.neuePflanzeDat.visible = true;
			},

			savePflanze(o){


				let self = this;
				let url = this.$store.state.server + '/be/savePflanze.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: o,
				})
				.then(function (resp) {



					self.pk = self.pkFilter = resp.data.data;

					self.pkFilterInput = o.name_lat;
					self.filterPkList();
				});
			},

			delPfItem(key){


				let tag = 'Soll der Eintrag " <strong>' + this.pk[key].name_lat + '</strong> " gelöscht werden?';


				this.dialog.data = {btn1: 'Abbrechen', btn2: 'OK', title: 'Löschen', text: tag};

				let self = this;
				this.dialog.callback = function(p){


					if (p === 1) {
						// let self = this;
						let url = self.$store.state.server + '/be/deletPkItem.php';


						axios.post(url, {
							crossDomain: true,
							headers: {
							},
							data: { o: self.pkFilter[key]},
						})
						.then(function (resp) {

							self.pk = self.pkFilter = resp.data.data;
							self.resetPkFilter();

						});
					}
					self.dialog.visible = false;
				};

				this.dialog.visible = true;



			},				
			renPfItem(){
				alert("renPfItem");
			},			
			toggleItemVis(key){

				let self = this;
				let url = this.$store.state.server + '/be/togglePkItemVis.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { id: this.pkFilter[key].id, vis: this.pkFilter[key].visible},
				})
				.then(function (resp) {



					let id = resp.data.data[0];
					let vis = resp.data.data[1];


					self.pkFilter[key].visible = vis;


				});

			},

			changeSelKat(){
				localStorage.setItem("lastSelKat", this.selKat);
				this.getIndex();
			 },
			getIndex(){

				if (localStorage.getItem("lastSelKat")) {
					this.selKat = localStorage.getItem("lastSelKat");
				} else {
					localStorage.setItem("lastSelKat", this.selKat);
				}

				this.resetPkFilter();

				let self = this;
				let url = this.$store.state.server + '/be/getPkIndex.php';



			axios.post(url, {
				crossDomain: true,
				headers: {
				},
				data: { kat: this.selKat},
			})
			.then(function (resp) {



				self.pkKats = resp.data.data[0];
				self.pk = self.pkFilter = resp.data.data[1];

				

				});
			},


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">
.pfk {
	border: 6px dotted #ffcf40;
	border-radius: 11px;
	padding: 10px;
	& select, input {
		cursor: pointer;
	}

	.items {
		width: 100%;
		display: flex;
		align-items: center;

		&:nth-child(even) {
			background-color: #eee;
		}

		& div.item {
			padding: 0 .4rem;
			margin: .2rem;
			// border: 1px solid silver;

			& .name_lat {
				width: 22rem;
			}
			&.preis {
				width: 5rem;
				text-align: right;
			}
			&.visible {
				width: 1rem;
				background-color: var(--c12);
				color: white;
				text-align: center;
			}
			&.img img {
				background-color: #EEE472;
				border-radius: 50%;
				border: 1px solid silver;
				cursor: pointer;
			}
		}
	}
}

.pfk .kat, .pfk .filter {
margin: .6rem 0;
display: flex;
align-items: center;
& div {
	padding: 0 .4rem;
}

& input {
	min-width: 26rem;
}

}

.pfk .icon.exit {

	position: relative;

	& div {
		position: absolute;
		right: 0;
		top: 0;
		stroke: #b97777;
		scale: 0.7;
		bottom: -1.1rem;
		cursor: pointer;
		& svg {
			width: 2rem;
			height: 2rem;

		}
	}
	
}

.pkList {
	height: 43rem;
	overflow: auto;
}

div.item.icons {
	display: flex;
	cursor: pointer;
	& svg {
		width: 1.7rem;
		height: 1.4rem;
		padding: 0 0.2rem;
	}	
}

.iEdit {
	fill: #7da481;
}
.iRen {
	fill: #a6b3a8;
}
.iDel {
	fill: #8a3131;
}
.iVis {
	stroke: #72b579;
}
.iUvis {
stroke: black;
}

.pfk .header {
	display: flex;
	margin: .4rem 0;
	& div {
	// background-color: #EEE472;
		flex: 0 0 25%;
	}
}

.pfk .header > div.newItem button{
border: 2px solid var(--c14);
	&:hover {
		color: white;
		background-color: var(--c12);
	}
}
	
.pfk button {
	border: 1px solid #ddd;
	padding: 0.3rem 2rem;
	border-radius: 6px;
	cursor: pointer;
	background-color: #eee;

	&:hover {
		color: white;
		background-color: var(--c12);
	}

}





</style>
