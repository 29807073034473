<template>
	<div class="modal">
		<div class="modalCont">


			<div class="modalHead">
				<div v-if="dialogData.title">
					{{ dialogData.title }}
				</div>
			</div>

			<div class="modalMain">
				<div v-if="dialogData.text" v-html="dialogData.text"></div>
			</div>

			<div class="modalFooter">
				<div  v-if="dialogData.btn1"><button :value="dialogData.btn1" @click="closeModal(0)">{{ dialogData.btn1 }}</button></div>
				<div  v-if="dialogData.btn2"><button :value="dialogData.btn2" @click="closeModal(1)">{{ dialogData.btn2 }}</button></div>
			</div>
		</div>
	</div>
</template>

<script>


	export default {
		name: 'Dialog',
		components: {

		},
		props:[
		'par'

		],
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
				dialogData : this.par.data,

			}
		}, // end data
		methods: {
			closeModal(p){


				// this.dialogData.response = p.target.value;
				this.par.callback(p);
			},



		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.modal {
	position: absolute;
	top: 0;
	left: 0rem;
	background-color: rgb(0,0,0, .5);
	width: 100vw;
	height: 100vh;
	z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;

		& > .modalCont {
			background-color: white;

			padding: 2rem;

			border-radius: 10px;
			box-shadow: 2px 2px 2px #555;

		}

		& .modalMain {
			min-height: 7rem;
			width: 48rem;
			text-align: center;

		}

		& .modalHead {
			text-align: center;
			background-color: #cea7a7;
			color: white;
			font-weight: bold;
			padding: 0.3rem 1rem;
			margin-bottom: 0.7rem;

		}
	& .modalFooter {
		display: flex;
		justify-content: center;
		align-items: baseline;
		
		& div {
			margin: 1rem;
			min-width: 12rem;

			& button {
				width: 100%;
			}
		}
	}
}


</style>
